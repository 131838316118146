




























































































































































import { contactService } from '@/services/modules/contact/contact'
import { contactService as emailSenderContactService } from '@/services/modules/contact/index'
import appConfig from '@/app.config.json'
import communique from '@/notification'
import LandingSection from '@/components/LandingSection.vue'
import PatternSaguaro from '@/components/PatternSaguaro.vue'
import i18n from '@/setup/i18n'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { computed, defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'LandingSectionContact',

  components: {
    LandingSection,
    ValidationObserver,
    ValidationProvider,
    PatternSaguaro,
  },

  setup() {
    const form = ref<HTMLFormElement | null>(null)
    const isSubmitting = ref(false)
    const firstName = ref('')
    const company = ref('')
    const email = ref('')
    const message = ref('')

    const firstNameLabel = computed(() =>
      i18n.t('contactFields.firstName.label')
    )
    const firstNamePlaceholder = computed(() =>
      i18n.t('contactFields.firstName.placeholder')
    )

    const companyLabel = computed(() => i18n.t('contactFields.company.label'))
    const companyPlaceholder = computed(() =>
      i18n.t('contactFields.company.placeholder')
    )

    const emailLabel = computed(() => i18n.t('contactFields.email.label'))
    const emailPlaceholder = computed(() =>
      i18n.t('contactFields.email.placeholder')
    )

    const messageLabel = computed(() => i18n.t('contactFields.message.label'))
    const messagePlaceholder = computed(() =>
      i18n.t('contactFields.message.placeholder')
    )

    async function onSubmit(): Promise<void> {
      contactService.insert({
        name: firstName.value,
        company: company.value,
        email: email.value,
        message: message.value,
      })

      try {
        isSubmitting.value = true

        await emailSenderContactService.send({
          applicationId: appConfig.api.applicationId,
          firstName: firstName.value,
          emailAddress: email.value,
          company: company.value,
          message: message.value,
        })

        firstName.value = ''
        company.value = ''
        email.value = ''
        message.value = ''

        form.value?.reset()

        communique.dispatch({
          variant: 'success',
          message: i18n.t('contactSubmitSuccess') as string,
        })
      } catch (error) {
        communique.dispatch({
          variant: 'error',
          message: i18n.t('contactSubmitError') as string,
        })
      } finally {
        isSubmitting.value = false
      }
    }

    return {
      isSubmitting,
      firstName,
      company,
      email,
      message,
      firstNameLabel,
      firstNamePlaceholder,
      companyLabel,
      companyPlaceholder,
      emailLabel,
      emailPlaceholder,
      messageLabel,
      messagePlaceholder,
      onSubmit,
      form,
    }
  },
})
