import { AxiosInstance } from 'axios'
import { api } from '../../client'
import { serviceUrl } from '../../utils'

const controller = serviceUrl('contact')

function createContactService(client: AxiosInstance) {
  return {
    send(data: ContactViewModel) {
      return client.post<void>(controller.action('send'), data)
    },
  }
}

export const contactService = createContactService(api.contact)

export interface ContactViewModel {
  applicationId: string
  firstName: string
  emailAddress: string
  company: string
  message: string
}
