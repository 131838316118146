var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LandingSection',{scopedSlots:_vm._u([{key:"background",fn:function(){return [_c('PatternSaguaro',{staticClass:"text-quaternary-500 bg-secondary-300 text-opacity-60 dark:text-quaternary-800 dark:bg-secondary-900 dark:bg-opacity-10 dark:text-opacity-50 absolute top-0 left-0 h-full w-full pointer-events-none",attrs:{"id":"saguaro-bg"}})]},proxy:true},{key:"default",fn:function(){return [_c('BaseBox',{staticClass:"grid gap-16 px-4 lg:grid-cols-2"},[_c('BaseBox',{staticClass:"grid gap-6 bg-white dark:bg-gray-800 rounded-md py-8 px-4 shadow-md"},[_c('BaseBox',{staticClass:"grid gap-4 text-center px-4",attrs:{"tag":"header"}},[_c('BaseHeading',{staticClass:"text-primary dark:text-gray-100 text-3xl font-bold",attrs:{"level":1}},[_c('i18n',{attrs:{"tag":false,"path":"title"}})],1),_c('BaseHeading',{staticClass:"text-gray-500 dark:text-gray-200",attrs:{"level":2}},[_c('i18n',{attrs:{"tag":false,"path":"copy"}})],1)],1),_c('ValidationObserver',{attrs:{"slim":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{ref:"form",staticClass:"grid gap-y-6",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('ValidationProvider',{attrs:{"name":_vm.firstNameLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInputText',{attrs:{"id":"name","placeholder":_vm.firstNamePlaceholder,"autocomplete":"name","type":"text"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('BaseFieldMessage',{attrs:{"id":"name-message","message":errors[0],"tone":"critical"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.companyLabel,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInputText',{attrs:{"id":"company","placeholder":_vm.companyPlaceholder,"autocomplete":"organization","type":"text"},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('BaseFieldMessage',{attrs:{"id":"company-message","message":errors[0],"tone":"critical"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.emailLabel,"rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInputText',{attrs:{"id":"email","placeholder":_vm.emailPlaceholder,"autocomplete":"email","type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('BaseFieldMessage',{attrs:{"id":"email-message","message":errors[0],"tone":"critical"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.messageLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseTextarea',{attrs:{"id":"message","placeholder":_vm.messagePlaceholder,"autocomplete":"off"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('BaseFieldMessage',{attrs:{"id":"message-message","message":errors[0],"tone":"critical"}})]}}],null,true)}),_c('BaseBox',{staticClass:"text-right"},[_c('BaseButton',{attrs:{"loading":_vm.isSubmitting,"tone":"quaternary","type":"submit"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":"submit"}})]},proxy:true},{key:"loading-text",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":"submitButtonLoading"}})]},proxy:true}],null,true)})],1)],1)]}}])})],1),_c('BaseBox',{staticClass:"grid place-items-center"},[_c('BaseLink',{attrs:{"name":"Quotation","tone":"quaternary","variant":"solid"}},[_c('i18n',{attrs:{"tag":false,"path":"cta.quote"}})],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }